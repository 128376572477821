import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, BrowserAuthError, } from "@azure/msal-browser";
import { useState, useEffect } from "react";
import { fetchData } from "../configurations/fetch"
import { graphConfig } from "../configurations/authConfig";

interface ProfileProps {
  showProfileData?: boolean;
}

interface GraphData {
  displayName: string,
  givenName: string,
  jobTitle: string,
  mail: string,
  businessPhones: string[],
  officeLocation: string,
  userPrincipalName: string,
  profilePicture: string
}


export function Profile({ showProfileData = true }: ProfileProps) {
  const { result, error, login } = useMsalAuthentication(InteractionType.Popup);
  const [graphData, setGraphData] = useState<GraphData | null>();

  useEffect(() => {
    if (!!graphData) {
      return
    }

    if (!!error) {
      if (error instanceof BrowserAuthError) {
        login(InteractionType.Redirect)
      }
    }

    if (result) {
      fetchData(graphConfig.graphMeEndpoint, result.accessToken)
        .then(data => {
          setGraphData(data)
        })
        .catch(error => 
          console.log(
            error)
            );
    }


  }, [error, result, graphData, login]);

  return (
    <div className="flex-1">
      <div className="flex items-center text-blue-900">
        {showProfileData && (
          <div className="flex flex-col mr-4 text-right">
            <span className="font-bold text-2xl">{graphData?.displayName}</span>
            <span className=" text-sm">
              {graphData?.mail ? graphData?.mail
                : graphData?.userPrincipalName}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}