import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    // instrumentationKey:process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
    instrumentationKey:"547aa787-1d19-49f5-b825-3e6fdebe1fdc",
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((environment:ITelemetryItem) => {
    environment.tags = environment.tags || [];
    environment.tags["ai.cloud.role"] = "video_management_system_web";
});

export { reactPlugin, appInsights };