import { Route, Routes } from "react-router-dom";
import { VideoInputOutputDTO } from "./models/video-input-output-dto";
import { Videos } from "./pages/videos";


interface RouterProps {
  changeSpinnerShowingState: (state: boolean) => void
  videoInputOutputDTO?: VideoInputOutputDTO | null;
  spinnerShowing: boolean;

}

export function Router({
  changeSpinnerShowingState,
  videoInputOutputDTO = null,
  spinnerShowing = false }: RouterProps) {
  return (
    <Routes>
      <Route path="/" element={
        <Videos
          videoInputOutputDTO={videoInputOutputDTO}
          spinnerShowing={spinnerShowing}
          changeSpinnerShowingState={changeSpinnerShowingState}
        />}
      />
    </Routes>
  );
}
