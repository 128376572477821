import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../services/queryClient";
import { Body } from "./Body";


export function Pages() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    useEffect(() => {
        if (!isAuthenticated) {
            instance.ssoSilent({
                scopes: []
            }).then((response) => {
                instance.setActiveAccount(response.account);
            }).catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.loginRedirect();
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AuthenticatedTemplate>
                    <Body />
                </AuthenticatedTemplate>
            </QueryClientProvider>
        </>
    )
}
