import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Router } from '../Router';
import { Veiculos } from './Veiculos';
import { useEffect, useState } from 'react';
import { VeiculoOutputDTO } from '../models/veiculo-output-dto';
import { VideoInputOutputDTO } from '../models/video-input-output-dto';
import { VideoInputOutput } from './VideoInputOutput';
import { Dayjs } from 'dayjs';
import { gerarLinksAsync, listarVideosAsync } from '../services/hooks/useVideos';
import { ResponseDTO } from '../models/response-dto';
import { SeverityStatus } from '../models/severity-status';
import { useAPIToken } from '../services/hooks/usoAuthenticationResult';
import { useGET } from '../services/hooks/useAxios';
import { AppBar, DrawerHeader, drawerWidth, Main } from './MUIComponents';
import { Header } from './Header';
import { useMediaQuery } from '@mui/material';


export default function PersistentDrawer() {

    const theme = useTheme();

    const [openLeft, setOpenLeft] = useState<boolean>(false);
    const [openRight, setOpenRight] = useState<boolean>(false);
    const [spinnerShowing, setSpinnerShowing] = useState<boolean>(false);

    const [veiculos, setVeiculos] = useState<VeiculoOutputDTO[] | null>(null);
    const [videos, setVideos] = useState<VideoInputOutputDTO[] | null>(null);
    const [videoInputOutputDTO, setVideoInputOutputDTO] = useState<VideoInputOutputDTO | null>(null)

    const [responseDTO, setResponseDTO] = useState<ResponseDTO | undefined>(undefined)

    const { data: token } = useAPIToken()

    const { data: listagemDeVeiculos } = useGET<VeiculoOutputDTO[]>("Videos/ListarVeiculosComVideos")


    useEffect(() => {
        listagemDeVeiculos && setVeiculos(listagemDeVeiculos)

    }, [listagemDeVeiculos])


    const changeSpinnerShowingState = (state: boolean) => (setSpinnerShowing(state))

    const OnDatePickerSearch = (veiculo: VeiculoOutputDTO, dia: Dayjs): boolean => {

        setVideoInputOutputDTO(null)
        setVideos(null)

        token && listarVideosAsync(veiculo.id, dia, token)
            .then(listagemDeVideos => {
                if (listagemDeVideos.length === 0) {
                    setResponseDTO({
                        severity: SeverityStatus.WARNING,
                        message:
                            [
                                {
                                    key: "Videos",
                                    value: ` Não foram encontrados vídeos no dia: ${dia.format("DD/MM/YYYY")}`
                                }
                            ]
                    } as ResponseDTO);
                    return false;
                }
                setVideos(listagemDeVideos)
                setVideoInputOutputDTO(null)
                setOpenLeft(false);
                setResponseDTO({
                    severity: SeverityStatus.SUCCESS,
                    message:
                        [
                            {
                                key: "Videos",
                                value: `Foram localizados ${listagemDeVideos.length} vídeos.`
                            }
                        ]
                } as ResponseDTO);
                return true;
            })
            .catch(() => {
                setResponseDTO({
                    severity: SeverityStatus.ERROR,
                    message:
                        [
                            {
                                key: "Servidor",
                                value: "Ocorreu um erro durante a consulta."
                            }
                        ]
                } as ResponseDTO);
                return false
            })

        return false;
    }

    const OnVideoClicked = (videoInputOutputDTO: VideoInputOutputDTO) => {
        setVideoInputOutputDTO(null)
        changeSpinnerShowingState(true)
        token && gerarLinksAsync(videoInputOutputDTO, token)
            .then(videoResult => {
                setVideoInputOutputDTO(videoResult)
                setOpenRight(false)
            })
    }

    const small = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <>
            <Box sx={{width:"100vw"}}>
                <AppBar position="fixed" openLeft={openLeft} openRight={openRight} >
                    <Toolbar className="bg-white flex justify-between">
                        <Header showVideos={!!videos}
                            openLeft={openLeft}
                            openRight={openRight}
                            HandleOpenLeft={() => setOpenLeft(!openLeft)}
                            HandleOpenRight={() => setOpenRight(!openRight)}
                        />
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: `${drawerWidth(small)}px`,
                        maxWidth:"100vw",
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: `${drawerWidth(small)}px`,
                            boxSizing: 'border-box',
                            maxWidth:"100vw"
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={openLeft}
                >
                    <DrawerHeader sx={{
                        justifyContent: 'flex-end',
                        maxWidth:"100vw",
                        minHeight:"30px"
                    }}>
                        <span className='font-bold text-sm text-center lg:text-xl'>
                            Selecione o Veículo
                        </span>
                        <IconButton onClick={() => setOpenLeft(!openLeft)}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <div className='overflow-x-hidden'>
                        {veiculos && veiculos.map((veiculo: VeiculoOutputDTO) => {
                            return <Veiculos
                                key={veiculo.id}
                                id={veiculo.id}
                                nome={veiculo.nome}
                                identificadorInterno={veiculo.identificadorInterno}
                                placa={veiculo.placa}
                                primeiraDataDisponivel={veiculo.primeiraDataDisponivel}
                                ultimaDataDisponivel={veiculo.ultimaDataDisponivel}
                                responseDTO={responseDTO}
                                OnDatePickerSearch={OnDatePickerSearch}
                                OnCleanResponseDTO={() => setResponseDTO(undefined)}
                            />
                        })}
                    </div>
                </Drawer>
                <Drawer
                    sx={{
                        width: `${drawerWidth(small)}px`,
                        flexShrink: 0,
                        maxWidth:"100vh",
                        '& .MuiDrawer-paper': {
                            width: `${drawerWidth(small)}px`,
                            boxSizing: 'border-box',
                            maxWidth:"100vw",
                        },
                        
                    }}
                    variant="persistent"
                    anchor="right"
                    open={openRight}
                >
                    <DrawerHeader sx={{
                        justifyContent: 'start',
                        maxWidth:"100vw",
                        minHeight:"30px"
                    }}>
                        <IconButton size='large' onClick={() => setOpenRight(!openRight)}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                        <span className='font-bold text-sm text-center lg:text-xl'>
                            Selecione o Horário
                        </span>
                    </DrawerHeader>
                    <Divider />
                    <div className='overflow-x-hidden'>
                        {videos && videos.map((video: VideoInputOutputDTO) => {
                            return <VideoInputOutput
                                key={video.id}
                                id={video.id}
                                veiculoId={video.veiculoId}
                                veiculoNome={video.veiculoNome}
                                dataDoVideo={video.dataDoVideo}
                                horaDoVideo={video.horaDoVideo}
                                camera1={video.camera1}
                                camera2={video.camera2}
                                camera3={video.camera3}
                                camera4={video.camera4}
                                OnVideoClicked={OnVideoClicked}
                            />
                        })}
                    </div>
                </Drawer>
                <Main openLeft={openLeft} openRight={openRight} small={small}>
                    <Router
                        videoInputOutputDTO={videoInputOutputDTO}
                        spinnerShowing={spinnerShowing}
                        changeSpinnerShowingState={changeSpinnerShowingState}
                    />

                </Main>
            </Box>
        </>
    );
}
