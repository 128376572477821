import { MsalProvider, MsalProviderProps } from "@azure/msal-react";
import { Pages } from "./components/Pages";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from "./components/ApplicationInsightsService";




export function App({ msalInstance }: MsalProviderProps | any) {
  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalProvider instance={msalInstance}>
          <Pages />
        </MsalProvider>
      </AppInsightsContext.Provider>
    </>
  );
}


