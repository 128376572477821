export const fetchData = (endpoint: string, accessToken: string) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(result => result.json())
        .catch(error => console.log(error));
}
export const fetchGeneralData = (endpoint: string, accessToken: string) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(result => {
            console.log(result)
            result.body?.getReader().read()
        })
        .catch(error => console.log(error));
}