import IconButton from "@mui/material/IconButton";
import { List, MonitorPlay } from "phosphor-react";
import { useState } from "react";
import { Logo } from "./Logo";
import { Profile } from "./Profile";
import { SignOutButton } from "./SignOutButton";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type HeaderProps={
  showVideos:boolean
  openLeft:boolean
  openRight:boolean
  HandleOpenLeft(): void
  HandleOpenRight(): void
}


export function Header({
  HandleOpenLeft,
  HandleOpenRight,
  openLeft=false,
  openRight=false,
  showVideos=false
}:HeaderProps) {
  const [openSignout, setOpenSignout] = useState<boolean>(false);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <header className="flex items-center justify-between w-[100vw] z-0" >
                        <div className='flex'>
                            <div className='flex mt-[-0.5rem]'>
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={HandleOpenLeft}
                                    edge="start"
                                    sx={{ mr: 2, ...(openLeft && { display: 'none' }) }}
                                >
                                    <List size={small?20:30} color="#01091f" weight="duotone" />
                                </IconButton>
                            </div>
                            <Logo small={small} />
                        </div>
                        <div>
                            <span className="text-blue-900 font-extrabold text-xs lg:text-2xl">
                                VIDEO MANAGEMENT SYSTEM
                            </span>
                        </div>
                        <div className='flex items-center justify-end'>
                            <div className='flex items-baseline justify-end hover:cursor-pointer' onClick={() => setOpenSignout(!openSignout)}>
                                <Profile showProfileData={!small} />
                                {openSignout && (
                                    <div className='absolute mt-[4rem] bg-white rounded transition-all'>
                                        <SignOutButton />
                                    </div>
                                )}
                            </div>
                            {showVideos && (
                                <div className='flex mt-[-0.5rem] justify-end'>
                                    <IconButton
                                        aria-label="open drawer"
                                        onClick={HandleOpenRight}
                                        edge="end"
                                        sx={{ mr: 2, ...(openRight && { display: 'none' }) }}
                                    >
                                        <MonitorPlay size={small?30:40} color="#01091f" weight="fill" />
                                    </IconButton>
                                </div>
                            )}
                        </div>
    </header>
  );
}
