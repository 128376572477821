import { LogLevel } from "@azure/msal-browser";


const clientId = process.env.REACT_APP_CLIENT_ID ?? '';
const tenantId = process.env.REACT_APP_TENANT_ID ?? '';
const redirectUri ="/"
export const msalConfig = {
    auth: {
        clientId, 
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level:LogLevel|any, message:string|any, containsPii:boolean|any) => {
                if (containsPii) {
                    return;
                }
                
            }
        }
    }
};


export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMePictureEndpoint: "https://graph.microsoft.com/v1.0/me/photos/48x48",

};

