import { HTMLAttributes } from "react"

interface DurationProps extends HTMLAttributes<string> {
  seconds: number,

}


export default function Duration({ className, seconds }: DurationProps) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {formatNumberToTime(seconds)}
    </time>
  )
}

export function formatNumberToTime(seconds: number): string {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(`${date.getUTCSeconds()}`)
  if (hh) {
    return `${hh}:${pad(`${mm}`)}:${ss}`
  }
  return `00:${mm}:${ss}`
}

function pad(string: string) {
  return ('0' + string).slice(-2)
}
