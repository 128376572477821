import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { Car } from "phosphor-react";
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { VeiculoOutputDTO } from "../models/veiculo-output-dto";
import { ResponseDTO } from "../models/response-dto";


interface CustomVeiculo extends VeiculoOutputDTO {
    OnDatePickerSearch: (veiculo: VeiculoOutputDTO, dia: Dayjs) => boolean;
    OnCleanResponseDTO: () => void;
    responseDTO: ResponseDTO | undefined;

}


export function Veiculos(veiculo: CustomVeiculo) {

    const { id, placa, identificadorInterno, responseDTO, OnDatePickerSearch, OnCleanResponseDTO } = veiculo

    const [open, setOpen] = useState<boolean>(false);

    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [selectDateValue, setSelectDateValue] = useState<Dayjs | null>(dayjs(veiculo.ultimaDataDisponivel));
    const minDateValue = dayjs(veiculo.primeiraDataDisponivel);
    const maxDateValue = dayjs(veiculo.ultimaDataDisponivel);


    const handleClickOpen = () => {
        OnCleanResponseDTO()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = () => {
        veiculo &&
            selectDateValue &&
            OnDatePickerSearch(veiculo, selectDateValue!) &&
            handleClose()
    }

    const handleSnackbar = () => {
        setOpenSnackBar(false)
        if (responseDTO?.severity === "success") handleClose()

    }

    const handleSnackbarDuration = (): number =>
        responseDTO?.severity === "success"
            ? 1000
            : 6000


    useEffect(() => {
        if (responseDTO) {
            setOpenSnackBar(true)
        }

    }, [responseDTO])

    return (
        <div key={id} className="flex flex-col pl-8 ml-[-2rem] p-2 h-23 gap-8 
            justify-center text-xl hover:cursor-pointer  hover:bg-blue-999 hover:text-white 
            hover:text-3xl hover:duration-[600ms]">
            <div className="flex items-center  group" onClick={handleClickOpen}>
                <div className="flex items-center ">
                    <Car size="20%" weight="duotone" />
                    <div className="flex flex-col items-center ">
                        <span className="mt-2 ml-2 text-sm lg:text-lg">Placa: {placa}</span>
                        <span className="mt-2 ml-2 text-xs lg:text-sm">Codigo: {identificadorInterno}</span>
                    </div>
                </div>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Selecione a data do vídeo a ser visualizado."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
                            <DatePicker
                                value={selectDateValue}
                                minDate={minDateValue}
                                maxDate={maxDateValue}
                                inputFormat={"DD/MM/YYYY"}
                                
                                onChange={(newValue) => {
                                    setSelectDateValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSearch}>
                        Pesquisar
                    </Button>
                    {responseDTO && (
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={openSnackBar} autoHideDuration={handleSnackbarDuration()} onClose={handleSnackbar}>
                            <Alert
                                severity={responseDTO?.severity}
                                onClose={handleSnackbar}
                                sx={{ width: '100%' }}>
                                {`${responseDTO?.message[0].key}:  ${responseDTO?.message[0].value}`}
                            </Alert>
                        </Snackbar>
                    )}
                </DialogActions>
            </Dialog>
        </div >
    )
}