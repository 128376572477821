import { useEffect, useState } from "react";
import { api } from "../api";
import { useAPIToken } from "./usoAuthenticationResult";



export function useGET<TOutput = unknown>(url: string) {
  const [data, setData] = useState<TOutput | null>(null);

  const { data: token, isLoading } = useAPIToken()

  useEffect(() => {
    token&& api.get(url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          AccessControlAllowOrigin: "*",
          AccessControlAllowHeaders: "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          AccessControlAllowMethods: "GET,HEAD,OPTIONS,POST,PUT",
          AccessControlAllowCredentials: "true"
        }
      }).then((response) => {
        setData(response.data);
      });
  }, [url, token, isLoading]);

  return { data };
}

export function usePOST<TOutput = unknown, TInput = unknown>(url: string, payload: TInput) {
  const [data, setData] = useState<TOutput | null>(null);

  const { data: token, isLoading } = useAPIToken()

  useEffect(() => {

    token&& api.post(url,payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          AccessControlAllowOrigin: "*",
          AccessControlAllowHeaders: "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          AccessControlAllowMethods: "GET,HEAD,OPTIONS,POST,PUT",
          AccessControlAllowCredentials: "true"
        }
      }).then((response) => {
        setData(response.data);
      });
  }, [payload, url, token, isLoading]);

  return { data };
}

export function usePUT<TOutput = unknown, TInput = unknown>(url: string, payload: TInput) {
  const [data, setData] = useState<TOutput | null>(null);

  const { data: token, isLoading } = useAPIToken()

  useEffect(() => {

    token&& api.put(url,payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          AccessControlAllowOrigin: "*",
          AccessControlAllowHeaders: "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          AccessControlAllowMethods: "GET,HEAD,OPTIONS,POST,PUT",
          AccessControlAllowCredentials: "true"
        }
      }).then((response) => {
        setData(response.data);
      });
  }, [payload, url, token, isLoading]);

  return { data };
}
