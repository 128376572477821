import { CorteDoVideo } from "../../models/corte-do-video";
import { VideoInputOutputDTO } from "../../models/video-input-output-dto";
import { api } from "../api";
import dayjs,{ Dayjs } from "dayjs";

export async function listarVideosAsync(veiculoId: string, dataDoVideo: Dayjs, token: string): Promise<VideoInputOutputDTO[]> {

  if(veiculoId ===null) return [];
  if(dataDoVideo ===null) return [];

  const { data } = await api.get(`Videos/ListarVideos/${veiculoId}/${dataDoVideo.format('YYYY-MM-DD')}`, {
    headers:
    {
      Authorization: `Bearer ${token}`,
      AccessControlAllowOrigin: "*",
      AccessControlAllowHeaders: "Origin, X-Requested-With, Content-Type, Accept"
    }
  });

  return handleVideos(data);
}

export async function gerarLinksAsync(videoInputOutputDTO: VideoInputOutputDTO, token: string): Promise<VideoInputOutputDTO> {
  const { data } = await api.post('Videos/GeraLinks', videoInputOutputDTO, {
    headers:
    {
      Authorization: `Bearer ${token}`,
      AccessControlAllowOrigin: "*",
      AccessControlAllowHeaders: "Origin, X-Requested-With, Content-Type, Accept"
    }
  });
  return data;
}

export async function gerarCorteAsync(corteDoVideoDTO: CorteDoVideo, token: string):Promise<any> {
  
  console.log("corteDoVideoDTO",corteDoVideoDTO)
  
  const { data } = await api.post('Videos/GeraCorte', corteDoVideoDTO, {
    headers:
    {
      Authorization: `Bearer ${token}`,
      AccessControlAllowOrigin: "*",
      AccessControlAllowHeaders: "Origin, X-Requested-With, Content-Type, Accept",
    },
    responseType: 'blob',
  });
  return data;
}

const handleVideos = (data: VideoInputOutputDTO[]): VideoInputOutputDTO[] => {
  const videos: VideoInputOutputDTO[] = data.map((video: VideoInputOutputDTO) => handleVideo(video));
  return videos;
}

const handleVideo = (video: VideoInputOutputDTO): VideoInputOutputDTO => {
  return {
    ...video,
    dataDoVideo: dayjs(video.dataDoVideo).format("DD/MM/YYYY"),
  };
}
