import LogoImg from '../assets/logoH3.png'
import SymbolImg from '../assets/logoH4.png'


type LogoProps = {
  small: boolean
}


export function Logo({ small }: LogoProps) {

  return (
    <div className='flex items-center'>
      {small ? (
        <img src={SymbolImg} alt="logo" className='xs:h-[20px] md:h-[30px] lg:h-[40px]' />

      ) : (
        <img src={LogoImg} alt="logo" className='xs:h-[20px] md:h-[30px] lg:h-[40px]' />
      )}
    </div>
  );
}
