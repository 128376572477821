import { PaletteOptions } from "@mui/material/styles/createPalette";
import createTheme from "@mui/material/styles/createTheme";




const palette: PaletteOptions = {

  primary: {
    main: "#01091f",
    contrastText: "#E1E1E6",
  },

  secondary:{
    main: "#E1E1E6",
    contrastText: "#01091f",
  },

  text: {
    primary: "#01091f",
    secondary: "#01091f",
  },
 };

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily:[
      'Gotham Light', 
      'Roboto', 
      'sans-serif'
    ].join(',')
  },
  
});


export default theme;
