import { MonitorPlay } from "phosphor-react";
import { VideoInputOutputDTO } from "../models/video-input-output-dto";

interface VideoComponetProps extends VideoInputOutputDTO {
    OnVideoClicked(video: VideoInputOutputDTO): void;
}

export function VideoInputOutput(videoInputOutputDTO: VideoComponetProps) {
    const { id, horaDoVideo, OnVideoClicked } = videoInputOutputDTO;
    return (
        <div key={id} className="flex flex-col pl-8 ml-[-2rem] p-2 h-24 gap-8 items-center justify-center
     text-xl hover:cursor-pointer  hover:bg-blue-999 hover:text-white 
        hover:text-3xl hover:duration-[600ms]" onClick={() => OnVideoClicked(videoInputOutputDTO as VideoInputOutputDTO)}>
            <div className="flex-col group pl-4">
                <div className="flex items-center justify-center ">
                    <MonitorPlay size="20%" weight="duotone" />
                    <div className="flex ">
                        <span className="mt-2 ml-2 text-sm lg:text-lg">Hora: {horaDoVideo}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}