import { useQuery } from "react-query";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";



export async function getToken(instance: IPublicClientApplication, account: AccountInfo): Promise<string> {

    return (await instance.acquireTokenSilent({
            // scopes: [process.env.REACT_APP_API_SCOPE_VMS!],
            scopes: ["api://5d8aa925-4986-41d1-aa50-8fe434688eb2/default"],
            account
        })).accessToken
}

export function useAPIToken() {
    const { instance, accounts } = useMsal();
    return useQuery("api-token", () => getToken(instance, accounts[0]), { staleTime: 1000 * 60 * 60 }); //60 min
}

