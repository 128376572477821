import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import theme from "../theme";
import AppBarProps from "./AppBarProps";

export function Body() {
  return (
    <div className="flex h-[100vh] w-[100vw]">
      <main className="flex flex-1">
        <BrowserRouter>
          <div className="flex rounded">
            <ThemeProvider theme={theme}>
              <AppBarProps />
            </ThemeProvider>
          </div>
        </BrowserRouter>
      </main>
    </div>
  );
}
