import { FormEvent, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ArrowCircleDown, ArrowsOut, Export, FastForwardCircle, PauseCircle, PlayCircle, Record, RewindCircle } from 'phosphor-react';
import { VideoInputOutputDTO } from '../../models/video-input-output-dto';
import Duration, { formatNumberToTime } from '../../components/Duration';
import screenfull from 'screenfull'


import style from "./Videos.module.css"

import '@vime/core/themes/default.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { findDOMNode } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { CorteDoVideo } from '../../models/corte-do-video';
import dayjs from 'dayjs';
import { CameraInputOutputDTO } from '../../models/camera-input-output-dto';
import { useAPIToken } from '../../services/hooks/usoAuthenticationResult';
import { gerarCorteAsync } from '../../services/hooks/useVideos';
import { ConvertBRLDateToSystemDateFormat } from '../../utils/convert-brl-date-to-systen-date';
interface videosProps {
  videoInputOutputDTO?: VideoInputOutputDTO | null
  spinnerShowing: boolean
  changeSpinnerShowingState: (state: boolean) => void
}

interface videoSourceProps {
  urlStream: string,
  urlDownload: string
}

export function Videos({
  changeSpinnerShowingState,
  videoInputOutputDTO = null,
  spinnerShowing = false }: videosProps) {

  const { data: token } = useAPIToken()

  const [played, setPlayed] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [playing, setPlaying] = useState<boolean>(false)
  const [playbackRate, setPlaybackRate] = useState<number>(1)
  const [cortesDosVideos, setCortesDosVideos] = useState<CorteDoVideo[]>()
  const [videosSources, setVideosSources] = useState<videoSourceProps[]>()
  const videoPlayers = [
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null)
  ]

  useEffect(() => {
    setPlaying(false)
    setPlayed(0)
    setDuration(0)
    setPlaybackRate(1)
    feedVideoSources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInputOutputDTO])


  function feedReference(player: ReactPlayer, index: number) {
    videoPlayers[index].current = player
  }

  function feedCorteCamera(videoInputOutputDTO: VideoInputOutputDTO, camera: CameraInputOutputDTO, cameraNumero: number): CorteDoVideo {
    return {
      camera: camera,
      inicioDoVideo: "00:00:00",
      fimDoVideo: "00:00:00",
      nomeDoVideo: `camera${cameraNumero}_${videoInputOutputDTO.veiculoNome}_${dayjs(ConvertBRLDateToSystemDateFormat(videoInputOutputDTO.dataDoVideo)).format("YYYY-MM-DD")}_${videoInputOutputDTO.horaDoVideo}`,
      acoes: "gravar"
    }

  }

  function feedVideoSources() {
    setVideosSources([])

    if (!videoInputOutputDTO) { return }

    const tempSource: videoSourceProps[] = []
    const tempCorte: CorteDoVideo[] = []



    if (videoInputOutputDTO?.camera1?.videoLink) {
      tempSource.push({
        urlStream: videoInputOutputDTO.camera1.videoLink,
        urlDownload: videoInputOutputDTO.camera1.linkDoVideoOriginal
      } as videoSourceProps)

      tempCorte.push(feedCorteCamera(videoInputOutputDTO, videoInputOutputDTO.camera1, 1))
    }

    if (videoInputOutputDTO?.camera2?.videoLink) {
      tempSource.push({
        urlStream: videoInputOutputDTO.camera2.videoLink,
        urlDownload: videoInputOutputDTO.camera2.linkDoVideoOriginal
      } as videoSourceProps)

      tempCorte.push(feedCorteCamera(videoInputOutputDTO, videoInputOutputDTO.camera2, 2))
    }

    if (videoInputOutputDTO?.camera3?.videoLink) {
      tempSource.push({
        urlStream: videoInputOutputDTO.camera3.videoLink,
        urlDownload: videoInputOutputDTO.camera3.linkDoVideoOriginal
      } as videoSourceProps)

      tempCorte.push(feedCorteCamera(videoInputOutputDTO, videoInputOutputDTO.camera3, 3))
    }

    if (videoInputOutputDTO?.camera4?.videoLink) {
      tempSource.push({
        urlStream: videoInputOutputDTO.camera4.videoLink,
        urlDownload: videoInputOutputDTO.camera4.linkDoVideoOriginal
      } as videoSourceProps)

      tempCorte.push(feedCorteCamera(videoInputOutputDTO, videoInputOutputDTO.camera4, 4))
    }

    setVideosSources(tempSource)
    setCortesDosVideos(tempCorte)

  }

  function handleSeek(seek: number) {
    setPlaying(false);

    changeSpinnerShowingState(true);

    if (!videoPlayers[0])
      return;

    const currentTime = videoPlayers[0].current!.getCurrentTime();
    videoPlayers[0]!.current!.seekTo(currentTime + (seek * playbackRate), "seconds");

    syncVideos(true);
  }

  function handleSliderSeek(seek: number) {
    setPlaying(false);

    changeSpinnerShowingState(true);

    if (!videoPlayers[0])
      return;

    // const currentTime = videoPlayers[0].current!.getCurrentTime();
    // console.log("handleSeek_currentTime", currentTime);
    // console.log("handleSeek_seek", seek);

    videoPlayers[0]!.current!.seekTo(seek, "fraction");

    syncVideos(true);
    setPlaying(false);
    syncVideos(false);
  }

  function handleDownload(event: FormEvent, source: string) {
    event.preventDefault();


    source &&
      window.open(source, '_blank', 'noopener,noreferrer');
  }

  function handlePlay() {
    setPlaying(false);
    changeSpinnerShowingState(true);
    syncVideos(true);
  }

  function handlePause() {
    setPlaying(false);
    syncVideos(false);
  }

  function handleOnReady() {
    if (!videoPlayers[0]) return;

    if (!spinnerShowing) return;

    setTimeout(() => {
      changeSpinnerShowingState(false);
    }, 2000);

  }

  function beginnerAction(shouldPlay: boolean = true) {
    setPlaying(false);
    changeSpinnerShowingState(shouldPlay);
  }

  function handleOnPlaybackRateChange(speed: number) {
    beginnerAction();

    if (!videoPlayers[0]) {
      syncVideos(false);
      return;
    }

    const currentTime = videoPlayers[0].current!.getCurrentTime()

    videoPlayers[0].current!.seekTo(speed, "seconds");

    videoPlayers.forEach(player => {
      player.current?.seekTo(currentTime, "seconds");
    })

    syncVideos(true);
  }

  function handleChangeSlider(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();
    const newValue = parseFloat(event.currentTarget.value);
    setPlayed(newValue)
    handleSliderSeek(newValue)
    setPlaying(false);
    handlePause()
  }

  function syncVideos(shouldPlay: boolean) {

    beginnerAction(shouldPlay);

    if (!shouldPlay) {
      setPlaying(shouldPlay);
      return;
    }


    if (!videoPlayers[0]) {
      beginnerAction(false);
      return
    }

    const currentTime = videoPlayers[0].current!.getCurrentTime()

    videoPlayers.forEach(player => {
      player.current?.seekTo(currentTime, "seconds");
    })


    setTimeout(function () {
      changeSpinnerShowingState(false);
      setPlaying(shouldPlay);
    }, 500);

  }

  function handleClickFullscreen(source: string) {
    const videoPlayer = videoPlayers.filter(player => player.current?.props.url === source)[0];
    const divElement = videoPlayer && videoPlayer.current ? findDOMNode(videoPlayer.current) : undefined;
    divElement && screenfull.request(divElement as Element)
  }

  function findCorteDoVideo(urlVideo: string): CorteDoVideo | null | undefined {
    return cortesDosVideos?.filter(c => c.camera.videoLink === urlVideo)[0]
  }

  function findVideoPlayer(urlVideo: string): React.MutableRefObject<ReactPlayer | null> {
    return videoPlayers.filter(c => c.current?.props.url === urlVideo)[0]
  }

  function handleClickRecord(urlVideo: string) {
    const corteDoVideo = findCorteDoVideo(urlVideo)
    if (!corteDoVideo) return

    const videoPlayer = findVideoPlayer(urlVideo)
    if (!videoPlayer || !videoPlayer.current) return

    const currentTime = formatNumberToTime(videoPlayer.current.getCurrentTime())

    const tempCortesDosVideos = cortesDosVideos?.filter((c) => c.camera.videoLink !== urlVideo)

    if (!tempCortesDosVideos) return

    switch (corteDoVideo.acoes) {
      case "gravar": {
        tempCortesDosVideos.push(
          {
            ...corteDoVideo,
            inicioDoVideo: currentTime,
            acoes: 'gravando'
          }
        )
        break;
      }
      case "gravando": {
        handlePause()
        tempCortesDosVideos.push(
          {
            ...corteDoVideo,
            fimDoVideo: currentTime,
            nomeDoVideo: `${corteDoVideo.nomeDoVideo}_de_${corteDoVideo.inicioDoVideo}_ate_${currentTime}`,
            acoes: 'exportar'
          }
        )

        break;
      }
      case "exportar": {
        handlePause()
        changeSpinnerShowingState(true)

        token && gerarCorteAsync(corteDoVideo, token)
          .then(res => {

            var blob = new Blob([res], {
              type: 'Content-Disposition',
            });

            const element = document.createElement('a');
            element.href = URL.createObjectURL(blob);
            element.setAttribute('download', `${corteDoVideo.nomeDoVideo}.mp4`);
            document.body.appendChild(element);
            element.click();
            changeSpinnerShowingState(false)
          })
          .catch(err => {
            console.log(err);
            changeSpinnerShowingState(false)
          })

        tempCortesDosVideos.push(
          {
            ...corteDoVideo,
            inicioDoVideo: "00:00:00",
            fimDoVideo: "00:00:00",
            acoes: 'gravar'
          }
        )

        break;
      }
    }

    setCortesDosVideos(tempCortesDosVideos)

  }

  function handleCorteStatus(urlVideo: string) {
    const corteDoVideo = findCorteDoVideo(urlVideo)
    if (!corteDoVideo) return

    switch (corteDoVideo.acoes) {
      case "gravar": {
        return (
          <>
            <Record size={xs ? "1rem" : "1.875rem"} weight="fill" />
            <span className='ml-1 mr-1 text-xs '>Gravar</span>
          </>
        )
      }
      case "gravando": {
        return (
          <>
            <Record size={xs ? "1rem" : "1.875rem"} weight="fill" color="#e70808" className='animate-pulse' />
            <span className='ml-1 mr-1 text-xs font-extrabold text-[#e70808] animate-pulse'>Gravando...</span>
          </>
        )
      }
      case "exportar": {
        return (
          <>
            <Export size={xs ? "1rem" : "1.875rem"} weight="fill" className='animate-pulse' />
            <span className='ml-1 mr-1 text-xs animate-pulse'>Exportar</span>
          </>
        )
      }
    }
  }

  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.down('lg'));


  return (
    <div className={style.videoPage}>
      <div className={style.videoPageLevel1}>
        {spinnerShowing &&
          (<div className={style.videoSpinner}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, width: '100%', height: '100%', position: "absolute" }}
              open={spinnerShowing}
            >
              <div className={style.videoSpinnerComplement}>
                <span className='mb-2'>carregando...</span>
                <CircularProgress color="inherit" />
              </div>
            </Backdrop>
          </div>)}
        {
          videoInputOutputDTO && videosSources && (
            <div className='flex-row items-center justify-center'>
              <div className='flex items-center xs:justify-center lg:justify-end group hover: bg-blue-999 text-white'>
                {!xs && (<div className='m-2'>
                  <span className='xs:text-xs lg:text-lg font-extrabold'>Veículo: {videoInputOutputDTO.veiculoNome}</span>
                </div>)}

                <div className={style.videoIconControl}
                  onClick={() => syncVideos(!playing)}>
                  {playing
                    ? (
                      <div className='flex items-center justify-center'>
                        <PauseCircle size="35%" weight="fill" />
                        <span className='ml-1 xs:text-[0px] lg:text-lg'>Pausar</span>
                      </div>
                    )
                    : (
                      <div className='flex items-center justify-center'>
                        <PlayCircle size="35%" weight="fill" />
                        <span className='ml-1 xs:text-[0px] lg:text-lg'>Inciar</span>
                      </div>
                    )
                  }
                </div>

                <div className={style.videoIconControl}
                  onClick={() => handleSeek(-10)}
                >
                  <div className='flex items-center justify-center'>
                    <RewindCircle size="35%" weight="fill" />
                    <span className='ml-1 xs:text-[0px]  lg:text-lg'>Recuar</span>
                  </div>
                </div>

                <div className={style.videoIconControl}
                  onClick={() => handleSeek(10)}
                >
                  <div className='flex items-center justify-center'>
                    <FastForwardCircle size="35%" weight="fill" />
                    <span className='ml-1 xs:text-[0px]  lg:text-lg'>Avançar</span>
                  </div>
                </div>

                <div>
                  <select
                    className={style.videoIconControl}
                    onChange={(e) => {
                      setPlaybackRate(Number(e.target.value))
                    }}
                  >
                    <option value={1}>1X</option>
                    <option value={2}>2X</option>
                    <option value={5}>5X</option>
                    <option value={10}>10X</option>
                    <option value={15}>15X</option>
                  </select>
                </div>

                {!xs && (<div className='flex xs:text-sm lg:text-lg  ml-4 font-extrabold w-[40rem]'>
                  <div className='mr-4'>
                    <span>Data: {videoInputOutputDTO.dataDoVideo}</span>
                  </div>
                  <div>
                    <span>Hora: {videoInputOutputDTO.horaDoVideo}</span>
                  </div>
                </div>)}
              </div>
              <div className='p-2 m-2 rounded w-[100%] flex justify-center items-center'
              >
                <input
                  className={style.customInput}
                  type='range' min={0} max={0.999999} step='any'
                  value={played}
                  onChange={(e) => handleChangeSlider(e)}
                />
                <div className='ml-4'>
                  <Duration seconds={duration * played} /> / <Duration seconds={duration} /></div>
              </div>
            </div>
          )}
        <div className="flex items-center justify-center  w-[100%]">
          <div className='xs:flex-row sm:flex sm:flex-wrap xs:w-[100vw] lg:w-[60%]'>

            {
              videosSources &&
              videosSources.map((source, key) => (
                <div key={key}
                  className="xs:flex-auto sm:flex-1 sm:items-stretch sm:w-[50%] sm:min-w-[25rem] sm:max-w-[50%] sm:rounded"
                >
                  <div className='bg-black'>

                    <ReactPlayer
                      playsInline
                      key={key}
                      style={{ borderRadius: "50rem", padding: "0.1rem" }}
                      height="100%"
                      width="100%"
                      url={source.urlStream}
                      playing={playing}
                      playbackRate={playbackRate}
                      muted={true}
                      onPlay={() => handlePlay}
                      // onSeek={(e) => console.log("Seek", e)}
                      onPause={() => handlePause}
                      onPlaybackRateChange={handleOnPlaybackRateChange}
                      onDuration={(d) => {
                        if (key === 0) setDuration(d)
                      }}
                      onProgress={(p) => {
                        if (key === 0) {
                          setPlayed(p.played)
                        }

                      }}
                      onReady={() => handleOnReady()}
                      ref={(player) => feedReference(player!, key)}
                      config={{
                        file: {
                          forceHLS: true,
                        }
                      }}
                    />

                    <div className='flex group hover:text-lg bg-white justify-end'>
                      <div className='
                       z-10
                       p-2 
                       justify-end
                       mt-[-3rem]
                       ml-[1rem]
                       rounded-2xl
                      text-blue-999
                       hover:cursor-pointer
                       duration:400ms'
                        onClick={(e) => handleDownload(e, source.urlDownload)}>
                        <div className='flex items-center justify-end hover:bg-white hover:rounded'>
                          <ArrowCircleDown size={xs ? "1.5rem" : "2.125rem"} weight="fill" />
                          <span className='ml-1 mr-1 text-xs'>Download</span>
                        </div>
                      </div>
                      <div className='
                       z-10
                       p-2 
                       justify-end
                       mt-[-3rem]
                       ml-[1rem]
                       rounded-2xl
                      text-blue-999
                       hover:cursor-pointer
                       duration:400ms'
                        onClick={() => handleClickFullscreen(source.urlStream)}>
                        <div className='flex items-center justify-end hover:bg-white hover:rounded'>
                          <ArrowsOut size={xs ? "1rem" : "1.875rem"} weight="fill" />
                          <span className='ml-1 mr-1 text-xs'>FullScreen</span>
                        </div>
                      </div>
                      <div className='
                       z-10
                       p-2 
                       justify-end
                       mt-[-3rem]
                       ml-[1rem]
                       rounded-2xl
                      text-blue-999
                       hover:cursor-pointer
                       duration:400ms'
                        onClick={() => handleClickRecord(source.urlStream)}>
                        <div className='flex items-center justify-end hover:bg-white hover:rounded'>
                          {handleCorteStatus(source.urlStream)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}  